//Control SideMenu
function controlSideMenu() {
  //変数設定
  let ww = window.innerWidth;
  let currentWidth = window.innerWidth;
  let breakpoint = 1024;

  //発火
  toggleAction();
  dropDownAction(ww);

  window.addEventListener("resize", function () {
    // ウインドウ横幅が変わっていない場合
    if ( currentWidth == window.innerWidth ) {
      return false;
    }
    currentWidth = window.innerWidth; // 横幅を更新
    $('[data-js="dropDown"] > a').off();//ドロップダウン初期化
    dropDownAction(currentWidth);
  });


  //トグル制御
  function toggleAction() {

    //閉じる
    function closeNav() {
      $('html').removeClass('open');
      $('#toggle').removeClass('is-toggleActive');
      $('#gnavi').removeClass('is-navOpen');
      $('.l-filter').removeClass('l-filter--show');
    }
    //開く
    function openNav() {
      $('html').addClass('open');
      $('#toggle').addClass('is-toggleActive');
      $('#gnavi').addClass('is-navOpen');
      $('.l-filter').addClass('l-filter--show');
    }

    $('#toggle').on('click', function() {
      if($(this).hasClass('is-toggleActive')) {
        closeNav();
      } else {
        openNav();
      }
    });

    //filterを押しても閉じる
    $('.l-filter').on('click', function() {
      closeNav();
    });
  }

  //ドロップダウン制御
  function dropDownAction(width) {
    $('[data-js="dropDown"] > a').on('click', function(e) {
      let clickBtn = $(this);
      let clickDropdown = $(this).siblings('.l-dropDown');
      e.preventDefault();

      //aタグクラス制御
      if(clickBtn.hasClass('is-dropdownActive')) {
        clickBtn.removeClass('is-dropdownActive');
      } else {
        clickBtn.addClass('is-dropdownActive');
      }

      if( width >= breakpoint ) {
        //PC動作
        clickDropdown.removeAttr('style');//style属性削除
        if (clickDropdown.hasClass('isDropdownOpen')) {
          clickDropdown.removeClass('isDropdownOpen');//閉じる
        } else {
          $('[data-js="dropDown"] > a').siblings('.l-dropDown').removeClass('isDropdownOpen');
          clickDropdown.addClass('isDropdownOpen');//開く
        }
      } else {
        //タブレット以下動作
        if (clickDropdown.hasClass('isDropdownOpen')) {
          clickDropdown.removeClass('isDropdownOpen');//閉じる
          clickDropdown.slideUp();
        } else {
          clickDropdown.addClass('isDropdownOpen');//開く
          clickDropdown.slideDown();
        }
      }
    });
  }
}
