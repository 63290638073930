//IE判定
//IEでサイトを見た場合、html開始タグに「is-IE」というクラスが付与される。

ieChecker();

function ieChecker() {
  let userAgent = window.navigator.userAgent.toUpperCase();
  let html = document.getElementsByTagName('html');
  if (userAgent.indexOf('MSIE') === -1 && userAgent.indexOf('TRIDENT') === -1) {
    // IE以外のブラウザでアクセス
    // console.log('OK');
  } else {
    // IEでアクセス
    // console.log("IEだ！");
    html[0].classList.add("is-IE");
  }
}